import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { renderFormField } from '~/components/internal/FormFields';
import Field from '~/components/internal/FormFields/Field';
import useCoreContext from '~/core/Context/useCoreContext';
/**
 * Installments generic dropdown
 */
function Installments(props) {
    var i18n = useCoreContext().i18n;
    var amount = props.amount, brand = props.brand, onChange = props.onChange;
    var _a = useState(1), installmentAmount = _a[0], setInstallmentAmount = _a[1];
    var installmentOptions = props.installmentOptions[brand] || props.installmentOptions.card;
    var getPartialAmount = function (divider) { return i18n.amount(amount.value / divider, amount.currency); };
    var onSelectInstallment = function (e) {
        var selectedInstallments = e.currentTarget.getAttribute('data-value');
        setInstallmentAmount(Number(selectedInstallments));
    };
    var installmentItemsMapper = function (value) { return ({
        id: value,
        name: amount.value ? value + "x " + getPartialAmount(value) : "" + value
    }); };
    useEffect(function () {
        var newAmount = installmentOptions && installmentOptions.values.includes(installmentAmount) ? installmentAmount : 1;
        setInstallmentAmount(newAmount);
    }, [brand]);
    useEffect(function () {
        onChange(installmentOptions ? installmentAmount : null);
    }, [installmentAmount, installmentOptions]);
    if (!installmentOptions)
        return null;
    if (amount.value === 0)
        return null;
    return (h("div", { className: "adyen-checkout__installments" },
        h(Field, { label: i18n.get('installments'), classNameModifiers: ['installments'] }, renderFormField('select', {
            items: installmentOptions.values.map(installmentItemsMapper),
            selected: installmentAmount,
            onChange: onSelectInstallment,
            name: 'installments'
        }))));
}
Installments.defaultProps = {
    brand: '',
    amount: {},
    onChange: function () { }
};
export default Installments;
