import { __assign, __extends } from "tslib";
import { h } from 'preact';
import { UIElement } from '../UIElement';
import CardInput from './components/CardInput';
import CoreProvider from '~/core/Context/CoreProvider';
import getImage from '~/utils/get-image';
import collectBrowserInfo from '~/utils/browserInfo';
import fetchJSONData from '~/utils/fetch-json-data';
var CardElement = /** @class */ (function (_super) {
    __extends(CardElement, _super);
    function CardElement(props) {
        var _this = _super.call(this, props) || this;
        _this.onBrand = function (event) {
            _this.eventEmitter.emit('brand', __assign(__assign({}, event), { brand: event.brand === 'card' ? null : event.brand }));
            if (_this.props.onBrand)
                _this.props.onBrand(event);
        };
        _this.onBinValue = function (callbackObj) {
            // Allow way for merchant to disallow binLookup by specifically setting the prop to false
            if (_this.props.doBinLookup === false) {
                if (_this.props.onBinValue)
                    _this.props.onBinValue(callbackObj);
                return;
            }
            // Do binLookup when encryptedBin property is present (and only if the merchant is using a clientKey)
            if (callbackObj.encryptedBin && _this.props.clientKey) {
                // Store id of request we're about to make
                _this.currentRequestId = callbackObj.uuid;
                fetchJSONData({
                    path: "v1/bin/binLookup?token=" + _this.props.clientKey,
                    loadingContext: _this.props.loadingContext,
                    method: 'POST',
                    contentType: 'application/json'
                }, {
                    supportedBrands: _this.props.brands,
                    encryptedBin: callbackObj.encryptedBin,
                    requestId: callbackObj.uuid // Pass id of request
                }).then(function (data) {
                    // If response is the one we were waiting for...
                    if (data && data.requestId === _this.currentRequestId) {
                        // ...call processBinLookupResponse with the response object
                        // if it contains at least one brand (a failed lookup will just contain requestId)
                        if (data.supportedBrands && data.supportedBrands.length) {
                            _this.processBinLookupResponse(data);
                        }
                    }
                });
            }
            else if (_this.currentRequestId) {
                // If onBinValue callback is called AND we have been doing binLookup BUT passed object doesn't have an encryptedBin property
                // - then the number of digits in number field has dropped below threshold for BIN lookup - so reset the UI
                _this.processBinLookupResponse(null);
                _this.currentRequestId = null; // Ignore any pending responses
            }
            if (_this.props.onBinValue)
                _this.props.onBinValue(callbackObj);
        };
        return _this;
    }
    CardElement.prototype.formatProps = function (props) {
        return __assign(__assign(__assign(__assign({}, props), { 
            // Mismatch between hasHolderName & holderNameRequired which can mean card can never be valid
            holderNameRequired: !props.hasHolderName ? false : props.holderNameRequired, 
            // Special catch for recurring bcmc (i.e. card with no cvc field). Scenario?? - Dropin - One click with no details
            hasCVC: !((props.brand && props.brand === 'bcmc') || props.hideCVC) }), (props.brands && !props.groupTypes && { groupTypes: props.brands })), { type: props.type === 'scheme' ? 'card' : props.type });
    };
    /**
     * @private
     * Formats the component data output
     * @return {object} props
     */
    CardElement.prototype.formatData = function () {
        var cardBrand = this.state.additionalSelectValue || this.props.brand;
        var includeStorePaymentMethod = this.props.enableStoreDetails && typeof this.state.storePaymentMethod !== 'undefined';
        return __assign(__assign(__assign(__assign({ paymentMethod: __assign(__assign(__assign(__assign({ type: CardElement.type }, this.state.data), (this.props.storedPaymentMethodId && { storedPaymentMethodId: this.props.storedPaymentMethodId })), (cardBrand && { brand: cardBrand })), (this.props.fundingSource && { fundingSource: this.props.fundingSource })) }, (this.state.billingAddress && { billingAddress: this.state.billingAddress })), (includeStorePaymentMethod && { storePaymentMethod: Boolean(this.state.storePaymentMethod) })), (this.state.installments && this.state.installments.value && { installments: this.state.installments })), { browserInfo: this.browserInfo });
    };
    CardElement.prototype.updateStyles = function (stylesObj) {
        if (this.componentRef && this.componentRef.updateStyles)
            this.componentRef.updateStyles(stylesObj);
        return this;
    };
    CardElement.prototype.setFocusOn = function (fieldName) {
        if (this.componentRef && this.componentRef.setFocusOn)
            this.componentRef.setFocusOn(fieldName);
        return this;
    };
    CardElement.prototype.processBinLookupResponse = function (binLookupObject) {
        if (this.componentRef && this.componentRef.processBinLookupResponse)
            this.componentRef.processBinLookupResponse(binLookupObject);
        return this;
    };
    Object.defineProperty(CardElement.prototype, "isValid", {
        get: function () {
            return !!this.state.isValid;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CardElement.prototype, "icon", {
        get: function () {
            return getImage({ loadingContext: this.props.loadingContext })(this.brand);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CardElement.prototype, "brands", {
        get: function () {
            var _this = this;
            if (this.props.brands) {
                return this.props.brands.map(function (brand) { return ({
                    icon: getImage({ loadingContext: _this.props.loadingContext })(brand),
                    name: brand
                }); });
            }
            return [];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CardElement.prototype, "brand", {
        get: function () {
            return this.props.brand || this.props.type;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CardElement.prototype, "displayName", {
        get: function () {
            if (this.props.storedPaymentMethodId) {
                return "\u2022\u2022\u2022\u2022 " + this.props.lastFour;
            }
            return this.props.name || CardElement.type;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CardElement.prototype, "browserInfo", {
        get: function () {
            return collectBrowserInfo();
        },
        enumerable: false,
        configurable: true
    });
    CardElement.prototype.render = function () {
        var _this = this;
        return (h(CoreProvider, { i18n: this.props.i18n, loadingContext: this.props.loadingContext },
            h(CardInput, __assign({ ref: function (ref) {
                    _this.componentRef = ref;
                } }, this.props, this.state, { onChange: this.setState, onSubmit: this.submit, payButton: this.payButton, onBrand: this.onBrand, onBinValue: this.onBinValue, brand: this.brand }))));
    };
    CardElement.type = 'scheme';
    return CardElement;
}(UIElement));
export { CardElement };
export default CardElement;
